/* after changing this file run 'npm run webapp:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
// Override Bootstrap variables
@import 'bootstrap-variables';
// Import Bootstrap source files from node_modules
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';
@import 'ng-uikit-pro-standard/assets/scss/bootstrap/bootstrap.scss';
@import 'ng-uikit-pro-standard/assets/scss/mdb.scss';
@import '../fonts/baryicons/style.css';

@import 'ng-uikit-pro-standard/assets/scss/addons-pro/_steppers-pro';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'google-place';
