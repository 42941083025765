$transporter_main_color: var(--transporter-primary);
$transporter_main_darken_5_color: var(--transporter-primary-color-darken-5);
$transporter_main_darken_15_color: var(--transporter-primary-color-darken-15);
$transporter_main_darken_20_color: var(--transporter-main-color-darken-20);
$transporter_main_lighten_2_color: var(--transporter-main-color-lighten-2);
$transporter_main_lighten_5_color: var(--transporter-main-color-lighten-5);
$transporter_main_lighten_50_color: var(--transporter-main-color-lighten-50);
$transporter_main_alpha_25_color: var(--transporter-main-color-alpha-25);
$transporter_main_text: var(--transporter-main-text);
$transporter_widget_color: var(--transporter_widget_color);
$transporter_highlighted_color: var(--transporter_highlighted_color);
$transporter_highlighted_hover_color: var(--transporter_highlighted_hover_color);
$transporter_highlighted_text_color: var(--transporter_highlighted_text_color);
$transporter_validation_color: var(--transporter_validation_color);
$transporter_validation_text_color: var(--transporter_validation_text_color);
$transporter_sidenavItem_color: var(--transporter_sidenavItem_color);
$transporter_sidenavItem_hover_color: var(--transporter_sidenavItem_hover_color);
$transporter_navbar_color: var(--transporter_navbar_color);
$transporter_navbar_text: var(--transporter_navbar_text);
$transporter_form_color: var(--transporter_form_color);
$transporter_form_text: var(--transporter_form_text);

// Colors:
// EXTRACT FIGMA : 24/02/2021
$primary: #003046 !default;
$primary: var(--transporter-primary);
$primary-color: $primary;
$primary-background-text: var(--primary-background-text);

$secondary: #5dc5f2 !default;
$secondary: var(--transporter-secondary);
$secondary-color: $secondary;
$secondary-background-text: var(--secondary-background-text);

$info: #17a2b8;
$warning: #856404;
$warning-light: #fff3cd;

$success: #4caf50;
$success-light: #d4edda;

$danger: #dc3545;
$danger-light: #f8d7da;
$danger-dark: #721c24;

$grey: #757575;
$grey-400: #bdbdbd;
$grey-200: #eeeeee;
$grey-50: #fafafa;

$dark: #262626;
$white: #fff;

$black-base: $dark;
$white-base: $white;
$disabled: #bdbdbd;

$input-error: #f44336;
