@import '../bary-variables';
@import '../mdb/overwrite-functions';
@import 'bootstrap/scss/functions';

/**
 Le template de WEPAL est un template basé sur celui de BARY afin de garder une compatibilité avec BARY et le reste de l'application.
 Nous le dédions seulement à la homepage
 */
html {
  scroll-behavior: smooth;
}

.wepal-skin {
  hr.wepal-hr {
    margin: 2rem auto;
    border: 0;
    width: 80%;
    border-top: 4px solid transparent;
  }

  @include media-breakpoint-up(sm) {
    jhi-bary-disconnected-nav-bar .navbar {
      padding: 0.5rem 3rem;
    }
  }

  // HOMEPAGE
  .wepal-homepage {
    background-color: #f8f7f7;

    .card-title h4 {
      font-weight: bold;
    }

    .section-sub-header {
      .lead {
        font-size: 16px;
      }
    }

    section.section-sub-header {
      div {
        vertical-align: middle;
      }

      svg g {
        fill: $secondary;
      }

      display: block !important;
    }

    section.section-splash {
      display: block;
      height: 80%;
      background: $primary;
      background: linear-gradient(180deg, $primary 35%, $primary 100%);
      min-height: 550px;

      h2.offer-title {
        padding-bottom: 1.5rem;
        color: #262626;
      }

      h2 {
        color: white;
      }

      .splash-arrow-down {
        color: white;
        margin: auto;
      }
    }

    section.section-jumbotron {
      min-height: 300px;

      .jumbotron {
        border-radius: 15px;

        .view {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          background-position: center;
          background-size: cover;
        }

        li {
          &::marker {
            color: $secondary;
          }
        }
      }
    }

    section.section-faq {
      .accordion {
        .card-header {
          min-height: 78px;
        }

        .card-body {
          font-size: 18px;
          line-height: 24px;
        }

        mdb-accordion-item-body {
          padding: 0 16px;
          text-align: justify;
        }

        h5 {
          font-size: 18px;
        }
      }
    }

    footer.page-footer {
      background: $primary;
      background: linear-gradient(180deg, $primary 35%, $primary 100%);
      color: white !important;

      .btn {
        background-color: white;
        color: $primary;
      }

      .footer-icon {
        padding-right: 10px;
      }

      .background-primary {
        background-color: $primary;
      }

      .bary-copyright img {
        max-height: 80px;
      }

      h3 {
        color: white;
      }

      .description {
        p {
          line-height: 2rem;
        }
      }

      p.color-white {
        color: white !important;
      }

      .img-fluid {
        max-width: 100%;
      }
    }

    .pricing-card.card {
      border-radius: 0.8rem;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      .card-header {
        background: linear-gradient($primary, darken($primary, 10%)) !important;
        border-radius: 0.8rem 0.8rem 0 0;

        .lead {
          color: white !important;
        }
      }

      .card-footer {
        background-color: #e3e3e3;
        border-top: none;
        border-radius: 0 0 0.8rem 0.8rem;
        padding-bottom: 1.5rem;
      }
    }

    .bary-btn:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
    }

    .add-btn {
      background-color: transparent;
    }

    .transport-item-line {
      padding-left: 1rem;
      padding-right: 1rem;
      font-weight: 400;
    }

    p {
      font-size: 18px;
      line-height: 24px;
    }

    h2 {
      font-weight: bold;
    }

    .wrapper-evolutrans {
      margin: 0;
      padding: 0;
    }

    .owl-item {
      padding: 20px;

      .card {
        border-radius: 10px;
      }

      .card-body {
        p {
          height: 50px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .img-fluid {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .owl-prev,
    .owl-next {
      font-weight: bold;
      font-size: 40px;
      padding: 4px 24px;
      border-radius: 50px;
      background-color: $primary;
      box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;

      &:hover {
        background-color: $secondary;
      }
    }
  }

  //MODAL
  .modal-wepal {
    .header {
      height: 40px;
      align-items: center;
    }

    .bary-btn:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
    }

    .modal.fade .modal-dialog {
      margin-top: 30vh;
    }

    .bary-option-group .bary-option-group-item {
      padding: 4px;

      &:last-child {
        margin-right: 5px;
      }

      // icone
      &.enabled i {
        color: $primary;
      }

      &.active i,
      &:hover i {
        color: white !important;
      }

      // item
      .enabled {
        background-color: white;
        color: $primary;
      }

      &.active,
      &:hover {
        background: linear-gradient(to left, $secondary, lighten($secondary, 10%)) !important;
        color: white !important;
        border-image: linear-gradient(to left, $secondary, lighten($secondary, 10%)) !important;
      }
    }

    .btn:hover,
    .btn-active {
      background-color: $secondary !important;
      color: white !important;
    }

    jhi-wepal-input-select {
      mdb-select-2 {
        border-radius: inherit;
      }

      .mdb-select-wrapper {
        border-radius: inherit;
      }
    }

    .fa-close {
      cursor: pointer;
    }

    .border-tr-15 {
      input {
        border-top-right-radius: 1rem;
      }

      .mdb-select-value {
        border-top-right-radius: 1rem;
      }
    }

    .md-form.wepal-select {
      margin: 0.2rem 0 0.5rem 0;

      .mdb-select-outline .mdb-select-value.focused {
        border: hsla(var(--transporter-primary-h), var(--transporter-primary-s), var(--transporter-primary-l), 50%) !important;
        box-shadow: inset 0 0 0 2px hsla(var(--transporter-primary-h), var(--transporter-primary-s), var(--transporter-primary-l), 50%) !important;
      }
    }
  }

  .modal-wepal .bary-btn,
  .wepal-homepage .bary-btn,
  .wepal-homepage .btn-primary {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
    border: none !important;
    border-radius: 4px;
    color: white !important;
    padding: 0.4rem 0.8rem;
  }

  .modal-wepal .bary-btn,
  .wepal-homepage .bary-btn {
    background: linear-gradient(to left, $secondary, lighten($secondary, 10%)) !important;
  }

  .wepal-homepage .btn-primary,
  .wepal-homepage .section-jumbotron .bary-btn {
    background: linear-gradient(to left, $primary, lighten($primary, 10%)) !important;
  }
}
