@import '../scss/variables';

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.scrollable {
  overflow-y: auto;

  &.square::-webkit-scrollbar-track,
  &.square::-webkit-scrollbar-thumb {
    border-radius: 0 !important;
  }

  &.thin::-webkit-scrollbar {
    width: 6px;
  }

  &.transparent::-webkit-scrollbar,
  &.transparent::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $primary;
}

.w-10 {
  width: 10px;
}

.w-20 {
  width: 20px;
}

.w-30 {
  width: 30px;
}

.w-200px {
  width: 200px;
}

.w-305px {
  width: 305px;
}

.max-width-250px {
  max-width: 250px;
}

.max-width-240px {
  max-width: 240px;
}
