@font-face {
  font-family: 'baryicons';
  src: url('fonts/baryicons.eot?g4fc1k');
  src: url('fonts/baryicons.eot?g4fc1k#iefix') format('embedded-opentype'), url('fonts/baryicons.ttf?g4fc1k') format('truetype'), url('fonts/baryicons.woff?g4fc1k') format('woff'),
    url('fonts/baryicons.svg?g4fc1k#baryicons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='baryicon-'],
[class*=' baryicon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'baryicons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.baryicon-no-loading-constraint:before {
  content: '\e914';
}
.baryicon-load-ramp:before {
  content: '\e913';
}
.baryicon-crate_of_wine2:before {
  content: '\e910';
}
.baryicon-adr:before {
  content: '\e911';
}
.baryicon-surface-container:before {
  content: '\e90f';
}
.baryicon-crate_of_wine:before {
  content: '\e90d';
}
.baryicon-coal-pile:before {
  content: '\e90b';
}
.baryicon-biohazard:before {
  content: '\e901';
}
.baryicon-crane:before {
  content: '\e903';
}
.baryicon-frozen:before {
  content: '\e905';
}
.baryicon-pallet:before {
  content: '\e907';
}
.baryicon-animals:before {
  content: '\e909';
}
.baryicon-hatchback:before {
  content: '\e900';
}
.baryicon-quay-loading:before {
  content: '\e902';
}
.baryicon-side-load:before {
  content: '\e904';
}
.baryicon-top-load:before {
  content: '\e906';
}
.baryicon-truck-1:before {
  content: '\e908';
}
.baryicon-truck-2:before {
  content: '\e90a';
}
.baryicon-truck-3:before {
  content: '\e90c';
}
.baryicon-truck-4:before {
  content: '\e90e';
}
.baryicon-mounted-forklift:before {
  content: '\e912';
}
