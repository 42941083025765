@import '../bary-variables';
@import 'overwrite-functions';

$skins: () !default;
$skins: map-merge(
  (
    'bary': (
      'skin-primary-color': $primary,
      'skin-navbar': $grey-50,
      'skin-footer-color': $primary,
      'skin-accent': $primary,
      'skin-flat': $white,
      // IMPORTANT, il faut le laisser en transparent car il y a un bug sur le active du composant mdb-accordion.
      // https://mdbootstrap.com/support/angular/side-nav-mdb-accordian-item-head-active-color-change/
      'skin-sidenav-item': rgba(0, 0, 0, 0),
      //'skin-sidenav-item': $transporter_sidenavItem_color,
      'skin-sidenav-item-hover': rgba(0, 0, 0, 0),
      'skin-gradient-start': lighten($primary, 70%),
      'skin-gradient-end': lighten($primary, 30%),
      'skin-mask-slight': pink,
      'skin-mask-light': orange,
      'skin-mask-strong': $white,
      'skin-sn-child': $primary,
      'skin-btn-primary': $primary,
      'skin-btn-secondary': $secondary,
      'skin-btn-default': gray,
      'skin-btn-default-lighten-5': gray,
      'skin-btn-default-darken-20': gray,
      'skin-text': $grey,
    ),
  ),
  $skins
);

@import 'bootstrap/scss/functions';
@import 'ng-uikit-pro-standard/assets/scss/core/mixins';
@import 'overwrite-functions';
@import 'ng-uikit-pro-standard/assets/scss/core/colors';
@import 'ng-uikit-pro-standard/assets/scss/core/variables';
@import 'ng-uikit-pro-standard/assets/scss/core/variables-pro';
/*@import '~ng-uikit-pro-standard/assets/scss/core/bootstrap/functions';
@import '~ng-uikit-pro-standard/assets/scss/core/bootstrap/rfs';*/
@import 'ng-uikit-pro-standard/assets/scss/core/bootstrap/variables';
@import 'ng-uikit-pro-standard/assets/scss/core/msc/skins-pro';
@import 'ng-uikit-pro-standard/assets/scss/addons-pro/_timelines-pro.scss';
@import 'ng-uikit-pro-standard/assets/scss/addons-pro/_timeline-pro.scss';

@each $skin, $data in $skins {
  .#{$skin}-skin {
    // TYPO
    h1 {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 48px;
      color: $dark;
    }

    h2 {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      color: $dark;
    }

    h3 {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      color: $dark;
    }

    h4 {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: $dark;
    }

    h5 {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: $grey;
    }

    p {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $grey;
    }

    p.lead {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: $grey;
    }

    p.bold,
    span.bold {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $grey;
    }

    p.small {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $grey;
    }

    p.small-bold {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $grey;
    }

    .color-success {
      color: $success !important;
    }

    .color-gray {
      color: $grey !important;
    }

    .color-light-gray {
      color: $grey-400 !important;
    }

    .color-dark {
      color: $dark;
    }

    .black-text {
      color: $dark !important;
    }

    // require for pricingpack
    .text-green {
      color: #28a745ff !important;
    }

    .text-red {
      color: #dc3545ff !important;
    }

    .text-yellow {
      color: #ffc107ff !important;
    }

    p.button {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */
      letter-spacing: 0.01em;
      text-transform: uppercase;
    }

    hr.bary-hr,
    .baryhr {
      margin: 1rem 0;
      border: 0;
      width: 100%;
      border-top: 1px solid $grey-200;
    }

    .form-control::placeholder {
      color: #c9c9c9;
      opacity: 1;
      font-style: italic;
    }

    // TYPO

    header,
    main {
      padding-left: 0;
    }

    @media only screen and (min-width: 1441px) {
      header.authenticated,
      main.authenticated {
        padding-left: 240px;
      }
    }

    /* to prevent autofill on input @see: https://mdbootstrap.com/docs/angular/forms/inputs/ */
    @-webkit-keyframes autofill {
      to {
        color: #666;
        background: transparent;
      }
    }

    @keyframes autofill {
      to {
        color: #666;
        background: transparent;
      }
    }

    // création d'une ligne
    input:-webkit-autofill {
      -webkit-animation-name: autofill;
      animation-name: autofill;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }

    .md-form > input[type]:-webkit-autofill:not(.browser-default):not([type='search']) + label {
      transform: translateY(-14px);
    }

    // Fix issue from mdb
    .btn-outline-primary.btn-floating mdb-icon {
      margin: -2px;

      i {
        color: map-get($data, skin-btn-primary) !important;
      }
    }

    .btn-outline-secondary.btn-floating mdb-icon {
      margin: -2px;

      i {
        color: map-get($data, skin-btn-secondary) !important;
      }
    }

    // Align modal header to center
    .modal-header {
      justify-content: center;
    }

    // Si plusieurs ligne de form-row alors on limite les margin bottom, sauf pour le dernier.
    form .form-row.form-row-list:not(:last-child) .md-form {
      margin-bottom: 0 !important;
    }

    // disable overflow on body when modal is open
    &.modal-open {
      height: 100vh !important;
      overflow-y: hidden !important;
    }

    // définie une z-index supérieur afin de ne pas avoir de problème lors de l'utilisation
    // d'un gplace au sein d'une modal(z-index 1050)
    .pac-container.pac-logo {
      z-index: 1060;
    }

    // désactive le shadow de la sidebar
    /*.side-nav {
      box-shadow: none;
    }*/

    // force le z-index du dropdown
    .cdk-overlay-pane {
      z-index: 1051 !important;
    }

    // darkerise la couleur des hovers des champs input
    .md-outline input[type='text']:focus:not([readonly]),
    .md-outline input[type='password']:focus:not([readonly]),
    .md-outline input[type='email']:focus:not([readonly]),
    .md-outline input[type='url']:focus:not([readonly]),
    .md-outline input[type='time']:focus:not([readonly]),
    .md-outline input[type='date']:focus:not([readonly]),
    .md-outline input[type='datetime-local']:focus:not([readonly]),
    .md-outline input[type='tel']:focus:not([readonly]),
    .md-outline input[type='number']:focus:not([readonly]),
    .md-outline input[type='search-md']:focus:not([readonly]),
    .md-outline input[type='search']:focus:not([readonly]),
    .md-outline textarea:focus:not([readonly]) {
      border-color: transparentize($primary, 50%) !important;
      box-shadow: inset 0 0 0 1px transparentize($primary, 50%) !important;
    }

    // iso mdb5 tabs
    .classic-tabs {
      .active-if-child {
      }

      .nav {
        padding-bottom: 20px;

        li {
          padding: 0 20px;

          &:first-child {
            margin-left: 0;
            padding-left: 0;
          }

          a {
            color: $grey;
            font-weight: bold;
            padding: 12px;
            font-size: 13px;
            text-transform: uppercase;
            border-bottom: 3px solid transparent;

            &.active {
              color: $dark;
              border-bottom: 3px solid $dark;
            }

            &:not(.active) {
              margin-bottom: 0;
            }
          }
        }

        &.classic-tabs {
          margin: 0;
          padding-bottom: 10px;
        }
      }

      .tab-content {
        margin: 0;
        padding: 0;
        box-shadow: unset;
      }
    }

    // TYPO
    .asterisk {
      color: red;
      vertical-align: baseline;

      &::after {
        content: '*';
      }
    }

    // AFFICHAGE SETTINGS
    div.settings {
      input[type='number'] {
        max-width: 110px;
        width: 100%;
      }

      input[type='text'] {
        max-width: 220px;
        width: 100%;
      }

      input[type='date'] {
        max-width: 170px;
        width: 100%;
      }

      select {
        max-width: 158px;
        width: 100%;
      }

      .ml-2 > select {
        max-width: 250px;
      }

      label {
        //text-align: right;
      }

      label:after {
        content: ':';
      }
    }

    @include media-breakpoint-up(lg) {
      div.settings {
        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: 10px;
        align-items: baseline;

        label:after {
          content: ':';
        }
      }
    }

    @include media-breakpoint-down(md) {
      div.settings {
        display: flex;
        flex-direction: column;

        label {
          display: flex;
          margin-top: 10px;
          margin-bottom: 0;
        }

        label > div {
          width: 100%;
          display: flex;
          flex: 1;
          text-align: right;
        }
      }
    }

    .bary-table-list > table {
      $table_border_color: #f0f0f0;
      table-layout: auto;
      width: 100%;
      border-color: $table_border_color;

      th,
      td {
        vertical-align: middle;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;

        > span:not(.no-capitalize) {
          display: inline-block;
          text-transform: lowercase;
          vertical-align: middle;

          &:first-letter {
            text-transform: uppercase;
          }
        }
      }

      th {
        font-weight: 800;
        white-space: nowrap;
      }

      td {
        font-weight: 400;
      }

      @media (max-width: 1299.98px) {
        .hidden-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 150px;
        }
      }

      tr:hover {
        background-color: $table_border_color;
      }
    }

    .pagination .page-item.active .page-link,
    .pagination .page-item.active .page-link:hover {
      background-color: $primary;
    }

    a {
      font-weight: 400;
    }

    // Fix issue on dimension
    .cdk-overlay-container {
      z-index: 1055 !important;
    }

    .switch label input[type='checkbox']:checked + .lever:after {
      background-color: $primary;
      left: 1.5rem;
    }

    .switch label input[type='checkbox']:checked + .lever {
      background-color: transparentize($primary, 0.5);
    }

    .switch label input[type='checkbox']:disabled + .lever:after,
    .switch label input[type='checkbox']:disabled:checked + .lever:after {
      background-color: #bdbdbd; // default color disabled.
    }

    .bary-input-checkbox .error-message {
      // to allow error on checkbox
      position: inherit !important;
    }

    // affect une couleur plus légère au dropdown. de la navbar.
    .navbar-nav .nav-item .dropdown-menu a.dropdown-item:hover {
      color: set-notification-text-color($primary) !important;
    }

    // DROPDOWN
    #bary-cta-menu {
      .dropdown-menu {
        .dropdown-item:hover {
          color: set-notification-text-color($primary);
          background-color: $primary;
          cursor: pointer;
        }
      }
    }

    // TABLE
    .bary-skin table.table-sm {
      th,
      td {
        padding: 0.3rem;
      }
    }

    @include media-breakpoint-down(sm) {
      th,
      td {
        &.not-mobile {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(md) {
      th,
      td {
        &.not-tablet {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      th,
      td {
        &.not-desktop {
          display: none;
        }
      }
    }

    @media (max-width: 1299.98px) {
      th,
      td {
        &.not-desktop-xl {
          display: none !important;
        }
      }
    }

    tr.table-desktop-xl {
      display: table-row;
    }

    tr.table-desktop {
      display: none;
    }

    tr.table-tablet {
      display: none;
    }

    tr.table-mobile {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      tr.table-mobile {
        display: table-row;
      }
      tr.table-tablet {
        display: none !important;
      }
    }

    @include media-breakpoint-down(lg) {
      tr.table-desktop {
        display: none !important;
      }
      tr.table-tablet {
        display: table-row;
      }
    }

    @media (max-width: 1299.98px) {
      tr.table-desktop-xl {
        display: none !important;
      }
      tr.table-desktop {
        display: table-row;
      }
    }

    /** Badges */

    .badge-pill {
      padding: 0.4rem;
      min-width: 100px;
      font-size: 14px;
      font-weight: 400;
      background: $primary;
    }

    /** SLIDER Horaire */
    .custom-slider {
      .ngx-slider:not([disabled]) {
        .ngx-slider-selected {
          background: $primary;
        }

        .ngx-slider-pointer {
          background-color: $primary;

          &.ngx-slider-active:after {
            background-color: lighten($primary, 20%);
          }
        }

        .ngx-slider-selection {
          background-color: $primary;
        }
      }
    }

    .border-active {
      border: 2px solid $primary !important;
    }

    /*** FIX les ancien card ***/
    .card {
      background-color: white;

      .card-header {
        background-color: white;
        color: rgba(117, 117, 117, 1);
        border-bottom: 1px solid #eeeeee;
      }

      .card-search-header {
        background-color: white;
        color: rgba(117, 117, 117, 1);
        border-bottom: 1px solid #eeeeee;

        .suffix {
          position: absolute;
          right: 12px;
          top: 12px;
        }
      }
    }

    .timeline-main {
      li .step-content {
        &.border-success::before {
          border-left: 15px solid #00c851 !important;
          border-right: 0 solid #00c851 !important;
        }

        &.border-danger::before {
          border-left: 15px solid #ff3547 !important;
          border-right: 0 solid #ff3547 !important;
        }
      }

      li.timeline-inverted .step-content {
        &.border-success::before {
          border-left: 0 solid #00c851 !important;
          border-right: 15px solid #00c851 !important;
        }

        &.border-danger::before {
          border-left: 0 solid #ff3547 !important;
          border-right: 15px solid #ff3547 !important;
        }
      }
    }

    .transport-type-icon {
      width: 250px;
    }

    .bary-option-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;

      .bary-option-group-item {
        flex-grow: 1;
        align-items: center;
        margin: 5px;
        border-radius: 5px !important;
        display: flex;
        flex-direction: column;
        background-color: $grey-50;
        border: 1px solid $grey-50;

        box-shadow: none;

        i {
          font-size: 4em;
          color: $grey;
        }

        mdb-icon {
          color: $grey;
        }

        &:active,
        &:focus,
        &.enabled.active,
        &.active {
          border-color: $primary;
          box-sizing: border-box;

          i,
          mdb-icon {
            color: $primary;
          }
        }

        &.enabled {
          border-color: $grey;
          box-sizing: border-box;

          i,
          mdb-icon {
            color: $grey;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        .bary-option-group-item {
          width: 40%; // 2x2x2
        }
      }
    }

    @include media-breakpoint-up(sm) {
      jhi-bary-disconnected-nav-bar .navbar {
        padding: 0.5rem 6rem;
      }
    }

    .modal-xxl {
      max-width: 1350px;
    }

    .gplace-icon-container {
      position: absolute;
      right: 40px;
    }

    .gplace-loader {
      position: relative;
      height: 20px;
      width: 20px;
      display: inline-block;
      animation: gplacearound 5.4s infinite;
    }

    .gplace-suffix {
      position: absolute;
      right: 12px;
    }

    .gplace-valid-address {
      color: mediumseagreen;
    }

    .gplace-invalid-address {
      color: $input-error;
    }

    @keyframes gplacearound {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .gplace-loader::after,
    .gplace-loader::before {
      content: '';
      background: white;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      border-width: 2px;
      border-color: #333 #333 transparent transparent;
      border-style: solid;
      border-radius: 20px;
      box-sizing: border-box;
      top: 0;
      left: 0;
      animation: gplacearound 0.7s ease-in-out infinite;
    }

    .gplace-loader::after {
      animation: gplacearound 0.7s ease-in-out 0.1s infinite;
      background: transparent;
    }
  }
  // fin : ne rien mettre en dessous
}

@import '../skin/wepal-skin';

$datepicker-weekday-bg: darken($primary, 5%) !default;

// Body:
// Settings for the `<body>` element.
body {
  background-color: $grey-50;
}
